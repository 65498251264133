import React from 'react'

interface IProps {
  name: string
  amount: string
  period: string
  workers: string
  boxClassName: string
  delay: string
}

export const Prices = ({
  name,
  amount,
  period,
  workers,
  boxClassName,
  delay,
}: IProps) => {
  return (
    <div
      className="col-md-4 col-sm-12"
      style={{ marginBottom: '20px', minHeight: '100%' }}
    >
      <div className={boxClassName} data-wow-delay={delay}>
        <div>
          <div
            className="header"
            style={{
              fontSize: '3rem',
              height: '50px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center', // Centers horizontally
              alignItems: 'center', // Centers vertically
              width: '100%', // Ensures it spans full width
            }}
          >
            <h1 style={{ textAlign: 'center' }}>{name}</h1>
          </div>
          <div className="price">
            <span
              className="amount"
              style={{ fontSize: amount.length > 10 ? '2.25rem' : '5rem' }}
            >
              {amount}
            </span>
            <span className="period">{period}</span>
          </div>
        </div>
        <ul className="items">
          {Array.isArray(workers) ? (
            workers.map((worker, index) => <li key={index}>{worker}</li>)
          ) : (
            <li>{workers}</li>
          )}
        </ul>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdHGnZPwCa-c1n-OEtL9uAee2PK1DrLHAwv5qA1oe2AenUXhw/viewform?c=0&w=1"
          target="_blank"
          className="pricing-btn arrow-btn"
          rel="noreferrer"
          aria-label="Formulário de inscrição"
        >
          Formulário de inscrição
          <span className="arrow"></span>
        </a>
      </div>
    </div>
  )
}
