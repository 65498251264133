import React from 'react'

export const IconsSlider = () => {
  const logoImages = [
    { src: 'avbox.webp', href: '' },
    { src: 'edc.webp', href: '' },
    { src: 'hama.webp', href: 'https://www.hama.pt' },
    { src: 'lop.webp', href: 'https://www.lop.pt' },
    { src: 'promutatis.webp', href: 'https://www.promutatis.com' },
  ]

  return (
    <div className="container">
      <div className="wow fadeInUp" data-wow-delay=".2s">
        {logoImages.map((logo, index) =>
          logo.href ? (
            <a key={index} href={logo.href} target="_blank" rel="noreferrer">
              <img
                src={`/images/logos/${logo.src}`}
                alt={logo.src}
                className="logo-image"
              />
            </a>
          ) : (
            <img
              key={index}
              src={`/images/logos/${logo.src}`}
              alt={logo.src}
              className="logo-image"
            />
          ),
        )}
      </div>
    </div>
  )
}
