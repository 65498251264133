import React from 'react'

export default function Newsletter() {
  return (
    <section id="download" className="cta-newsletter-section parallax">
      <div className="container parallax-content">
        <div className="download-app">
          <h2 className="wow fadeInUp" data-wow-delay=".2s">
            Newsletter
          </h2>
        </div>

        <div className="newsletter">
          <h5 className="wow fadeInUp" data-wow-delay=".2s">
            Mantenha-se atualizado, subscreva a nossa newsletter
          </h5>

          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <form
                className="newsletter-form"
                method="post"
                action="//apgt.us14.list-manage.com/subscribe/post?u=71530c569c36419fbfb01a14b&amp;id=686ccb4664"
                noValidate
              >
                <div
                  className="subscribe-input wow fadeInUp"
                  data-wow-delay=".4s"
                  style={{
                    visibility: 'visible',
                    animationDelay: '0.4s',
                    animationName: 'fadeInUp', // Removido espaço extra
                  }}
                >
                  <label htmlFor="contact-email" className="sr-only">
                    Insira aqui o seu email
                  </label>
                  <input
                    className="txt"
                    type="email"
                    id="contact-email"
                    name="EMAIL"
                    autoComplete="on"
                    placeholder="Insira aqui o seu email"
                    required
                  />
                  <span></span>
                  <button
                    className="subscribe"
                    type="submit"
                    aria-label="Subscrever a newsletter"
                  >
                    Subscrever
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
