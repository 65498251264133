import React from 'react'

export default function SocialEntities() {
  return (
    <section id="orgaos" className="features-2-section">
      <div className="container">
        <div className="section-header text-center">
          <h2 className="wow fadeInUp" data-wow-delay=".2s">
            Orgãos Sociais
          </h2>
          <div className="row">
            <div className="icon-feature">
              <div className="icon-feature wow fadeInUp" data-wow-delay=".2s">
                <div className="content2">
                  <h4>Comissão Directiva</h4>
                  <div className="comissao-images">
                    <img
                      className="phone wow fadeInUp"
                      data-wow-duration=".8s"
                      data-wow-delay=".2s"
                      src="/images/photos/bw_vitor_rosa.webp"
                      alt="Vitor Rosa"
                    />
                    <img
                      className="phone wow fadeInUp"
                      data-wow-duration=".8s"
                      data-wow-delay=".2s"
                      src="/images/photos/bw_filipe_barbosa.webp"
                      alt="Filipe Barbosa"
                    />
                    <img
                      className="phone wow fadeInUp"
                      data-wow-duration=".8s"
                      data-wow-delay=".4s"
                      src="/images/photos/bw_2_vp.webp"
                      alt="Vasco Simões"
                    />
                  </div>
                  <p>
                    • Fundador: Vitor Rosa – Presidente
                    <br />
                    <br />
                    • Filipe Barbosa – Vice-Presidente
                    <br />
                    <br />• Vasco Simões – 2º Vice-Presidente
                  </p>
                </div>
              </div>
            </div>
            <div className="icon-feature">
              <div className="icon-feature wow fadeInUp" data-wow-delay=".4s">
                <div className="content2">
                  <h4>Mesa da Assembleia</h4>
                  <div className="comissao-images">
                    <img
                      className="phone wow fadeInUp"
                      data-wow-duration=".8s"
                      data-wow-delay=".2s"
                      src="/images/photos/bw_carlos_pereira.webp"
                      alt="Carlos Pereira"
                    />
                  </div>
                  <p>
                    • Carlos Pereira – Presidente
                    <br />
                    <br />
                    • Francilia Teixeira – Vice-Presidente
                    <br />
                    • Gonçalo Silva - Secretário
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="icon-feature">
              <div className="icon-feature wow fadeInUp" data-wow-delay=".6s">
                <div className="content2">
                  <h4>Conselho Fiscal</h4>
                  <div className="comissao-images">
                    <img
                      className="phone wow fadeInUp"
                      data-wow-duration=".8s"
                      data-wow-delay=".2s"
                      src="/images/photos/bw_pedro_boavida.webp"
                      alt="Pedro Boavida"
                    />
                  </div>
                  <p>
                    • Pedro Boavida – Presidente
                    <br />
                    <br />
                    • Rita Maurício – Vogal
                    <br />
                    • Joana Carvalho – Vogal <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
