import React, { useEffect, useState } from 'react'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    particlesJS: any
  }
}

import NavBar from './navBar'

export default function WhoAreWe() {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    // Adiciona Particles.js dinamicamente
    const particlesScript = document.createElement('script')
    particlesScript.src = '/js/particles.min.js'
    particlesScript.async = true

    particlesScript.onload = () => {
      if (window.particlesJS) {
        window.particlesJS('particles', {
          particles: {
            number: { value: 60, density: { enable: true, value_area: 800 } },
            color: { value: '#ffffff' },
            shape: { type: 'square' },
            opacity: { value: 0.2, random: true, anim: { enable: false } },
            size: { value: 5 },
            move: { enable: true, speed: 2 },
          },
        })
      }
    }

    document.body.appendChild(particlesScript)

    return () => {
      document.body.removeChild(particlesScript)
    }
  }, [])

  return (
    <>
      <NavBar isScrolled={isScrolled} />
      <section id="intro" className="cta-main-section parallax">
        {/* Particles Background */}
        <div id="particles" className="particle-bg"></div>

        {/* Parallax Container */}
        <div>
          <div data-depth="0.3">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="intro-text">
                    <h1 className="wow fadeInUp" data-wow-delay=".2s">
                      <b>APGT</b>
                      <br />
                      Associacão Portuguesa de Gadgets e Tecnologia
                    </h1>
                    <div className="btns">
                      <a
                        href="#apgt"
                        className="app-btn wow bounceIn"
                        data-wow-delay=".6s"
                      >
                        QUEM SOMOS
                      </a>

                      <a
                        href="#contact"
                        className="app-btn wow bounceIn"
                        data-wow-delay=".8s"
                      >
                        CONTACTOS
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
