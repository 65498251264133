import Affiliates from './sections/affiliates'
import ContactUs from './sections/contactUs'
import Header from './sections/header'
import Newsletter from './sections/newsletter'
import Objectives from './sections/objectives'
import React from 'react'
import SocialEntities from './sections/socialEntities'
import Statutes from './sections/statutes'
import WhoAreWe from './sections/whoAreWe'
export default function LandingPage() {
  return (
    <>
      <Header />
      <WhoAreWe />
      <Objectives />
      <SocialEntities />
      <Statutes />
      <Newsletter />
      <Affiliates />
      <ContactUs />
    </>
  )
}
