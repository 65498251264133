// LoginModal.js

interface IProps {
  onClose: () => void
  onLogin: (username: string) => void
}

import React, { useState } from 'react'

const LoginModal = ({ onClose, onLogin }: IProps) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = () => {
    // Perform authentication logic (you can add validation, etc.)
    // For simplicity, let's assume a successful login with any username/password
    onLogin(username)
    onClose()
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Login</h2>
        <form>
          <label>
            Username:
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <button aria-label="Login" type="button" onClick={handleLogin}>
            Login
          </button>
        </form>
      </div>
    </div>
  )
}

export default LoginModal
