import React from 'react'

// Make sure to use the correct path to your NavBar component

export default function WhoAreWe() {
  return (
    <>
      <section id="apgt" className="mockup-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="screens-mockup">
                <img
                  className="phone wow fadeInUp"
                  data-wow-duration=".8s"
                  data-wow-delay=".2s"
                  style={{ height: '100%', width: '100%' }}
                  src="/images/photos/bw_vitor_rosa.webp"
                  alt="apgt-presidente"
                />
              </div>
            </div>

            <div className="col-md-8">
              <div className="mockup-text">
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  APGT - Quem somos
                </h2>
                <span className="font-altwow fadeInUp" data-wow-delay=".2s">
                  Mensagem do Presidente{' '}
                </span>
                <br />
                <br />

                <p className="wow fadeInUp" data-wow-delay=".6s">
                  A <b>APGT - Associação Portuguesa de Gadgets e Tecnologia</b>{' '}
                  é uma organização sem fins lucrativos, criada em 2016, que tem
                  como fim principal promover em e a partir de Portugal, o
                  desenvolvimento, a investigação e inovação dos gadgets e
                  tecnologias e todos os produtos e serviços com estes conexos
                  e/ou relacionados. <br />
                  <br />
                  Neste fim, a <b>APGT</b> tem a pretensão de cooperar com o
                  Estado Português e com a União Europeia, de forma a apoiar e
                  promover o desenvolvimento instituições na área dos gadgets e
                  tecnologias, através de um certificado para o efeito. Importa
                  destacar que no mundo dos gadgets e tecnologias não existem
                  barreiras regionais. <br />
                  <br />
                  As empresas portuguesas que, pela sua pequena dimensão, não
                  têm delegações exteriores, ganham agora a possibilidade de
                  suprir essa falta, podendo inserir-se em mercados de difícil
                  acesso através do apoio e da promoção do seu desenvolvimento,
                  incluindo startup&apos;s e atividade de empreendedorismo.
                  <br />
                  <br />O destaque que a <b>APGT</b> pretende conferir, em
                  geral, aos Gadgets e Tecnologia, justifica-se pela importância
                  estratégica do setor e pelo contributo que estas áreas podem
                  significar para o desenvolvimento e para o reposicionamento de
                  Portugal no mercado internacional.
                  <br />
                  <br />A
                  <strong>
                    APGT - Associação Portuguesa de Gadgets e Tecnologia
                  </strong>
                  vai levar os produtos, as marcas e empresas portuguesas de
                  gadgets e produtos tecnológicos para o mundo.
                  <br />
                  <br />
                  <span className="font-altwow fadeInUp" data-wow-delay=".2s">
                    <b>Vitor Rosa</b>
                  </span>
                  <br />
                  Presidente da Direção
                  <br />
                  <a href="mailto:vitor.rosa@apgt.pt">vitor.rosa@apgt.pt</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
