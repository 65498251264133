import React, { useState, useEffect } from 'react'

interface IProps {
  isScrolled: boolean
}

export default function NavBar({ isScrolled }: IProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  // Adiciona/remover a classe 'scrolled' no body
  useEffect(() => {
    if (isScrolled) {
      document.body.classList.add('scrolled')
    } else {
      document.body.classList.remove('scrolled')
    }
  }, [isScrolled])

  const navStyles = {
    backgroundColor: isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent',
    transition: 'background-color 0.2s ease-in-out',
    boxShadow: isScrolled ? '0 0 10px rgba(0, 0, 0, 0.2)' : 'none',
    padding: '10px 0',
    color: isScrolled ? 'black' : 'white',
  }

  const scrollToSection = (id: string) => {
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
      setIsMenuOpen(false) // Close menu after clicking a link
    }
  }

  return (
    <nav className="navbar navbar-fixed-top" style={navStyles}>
      <div className="container">
        <div className="navbar-header">
          <button
            aria-label="Menu"
            type="button"
            className="navbar-toggle"
            onClick={toggleMenu}
            style={{
              border: 'none',
              background: 'transparent',
            }}
          >
            <span
              className="icon-bar"
              style={{
                backgroundColor: isScrolled ? 'black' : 'white',
                display: 'block',
                height: '3px',
                width: '25px',
                margin: '5px 0',
              }}
            ></span>
            <span
              className="icon-bar"
              style={{
                backgroundColor: isScrolled ? 'black' : 'white',
                display: 'block',
                height: '3px',
                width: '25px',
                margin: '5px 0',
              }}
            ></span>
            <span
              className="icon-bar"
              style={{
                backgroundColor: isScrolled ? 'black' : 'white',
                display: 'block',
                height: '3px',
                width: '25px',
                margin: '5px 0',
              }}
            ></span>
          </button>
          <a href="#" className="navbar-brand">
            {isScrolled ? (
              <img src="/images/logo-dark.webp" alt="logo AGPT" />
            ) : (
              <img src="/images/logo-white.webp" alt="logo AGPT" />
            )}
          </a>
        </div>

        {/* Updated Dropdown Menu */}
        <div
          className={`navbar-collapse ${isMenuOpen ? 'open' : ''}`}
          style={{
            display: isMenuOpen ? 'block' : 'none',
            zIndex: 1000,
            backgroundColor: 'white', // White dropdown background
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow
          }}
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href="#intro"
                className="dropdown-link"
                onClick={(e) => {
                  e.preventDefault()
                  scrollToSection('intro')
                }}
              >
                HOME
              </a>
            </li>
            <li>
              <a
                href="#apgt"
                className="dropdown-link"
                onClick={(e) => {
                  e.preventDefault()
                  scrollToSection('apgt')
                }}
              >
                APGT
              </a>
            </li>
            <li>
              <a
                href="#orgaos"
                className="dropdown-link"
                onClick={(e) => {
                  e.preventDefault()
                  scrollToSection('orgaos')
                }}
              >
                Orgãos Sociais
              </a>
            </li>
            <li>
              <a
                href="#pricing"
                className="dropdown-link"
                onClick={(e) => {
                  e.preventDefault()
                  scrollToSection('pricing')
                }}
              >
                ASSOCIADOS
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="dropdown-link"
                onClick={(e) => {
                  e.preventDefault()
                  scrollToSection('contact')
                }}
              >
                CONTACTOS
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
