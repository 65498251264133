import React from 'react'
import { IconsSlider } from '../../../components/iconsSlider'
import { Prices } from '../../../components/prices'

export default function Affiliates() {
  return (
    <section id="pricing" className="pricing-section">
      <div className="container">
        <div className="section-header text-center">
          <h2 className="wow fadeInUp" data-wow-delay=".2s">
            ASSOCIADOS
          </h2>
          A<b>APGT - Associação Portuguesa de Gadgets e Tecnologia</b>{' '}
          destina-se a todos os profissionais, docentes, estudantes, formadores,
          consultores, investigadores e todas as outras entidades envolvidas no
          sector tecnológico.
          <br />
          <br />
          <IconsSlider />
          <br />
          <br />
          <h2 className="wow fadeInUp" data-wow-delay=".2s">
            Sócios Institucionais
          </h2>
        </div>

        <div className="row">
          <Prices
            name="Micro-Empresa"
            amount={'120'}
            period="/ ano"
            workers={'menos de 10 trabalhadores'}
            boxClassName="p-table wow fadeInRight"
            delay=".8s"
          />
          <Prices
            name="Pequena Empresa"
            amount={'240€'}
            period="/ ano"
            workers={'10 a 50 trabalhadores'}
            boxClassName="p-table standard wow bounceIn"
            delay=".2s"
          />
          <Prices
            name="Média Empresa"
            amount={'600€'}
            period="/ ano"
            workers={'50 a 250 trabalhadores'}
            boxClassName="p-table wow fadeInLeft"
            delay=".8s"
          />
        </div>

        <div className="row">
          <Prices
            name="Grande Empresa ou Multinacional"
            amount={'1200€'}
            period="/ ano"
            workers={'mais de 250 trabalhadores'}
            boxClassName="p-table wow fadeInRight"
            delay=".8s"
          />
          <Prices
            name="Instituições Públicas"
            amount={'Regime Equivalente às Empresas'}
            period=""
            workers={'Regime Equivalente às Empresas'}
            boxClassName="p-table standard wow bounceIn"
            delay=".2s"
          />
          <Prices
            name="Associações por reciprocidade"
            amount={'Permuta'}
            period=""
            workers={''}
            boxClassName="p-table wow fadeInLeft"
            delay=".8s"
          />
        </div>

        <div className="col-md-12">
          <div className="mockup-text">
            Sócios Individuais - 60€/ano
            <br />
            Estudantes: 20€/ano
            <br />
            <br />
            <br />
            <p className="wow fadeInUp" data-wow-delay=".6s">
              <strong>Preencha o nosso formulário de inscrição</strong>
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdHGnZPwCa-c1n-OEtL9uAee2PK1DrLHAwv5qA1oe2AenUXhw/viewform?c=0&w=1"
              target="_blank"
              className="btn-custom arrow-btn wow fadeInUp"
              data-wow-delay=".8s"
              rel="noreferrer"
            >
              FORMULÁRIO DE INSCRIÇÃO
              <span className="arrow"></span>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
